import { graphql, Link } from 'gatsby';
import React from 'react';
import Button from '~components/common/Button';
import Container from '~components/common/Container';
import Disclosure from '~components/common/Disclosure';
import FeatureList from '~components/common/FeatureList';
import ImageVideo from '~components/common/ImageVideo';
import Layout from '~components/layouts/Default';
import Cta from '~components/sections/Cta';
import PageHeader from '~components/sections/PageHeader';
import H2 from '~components/typography/H2';
import P from '~components/typography/P';

function Page({ location, data }) {
  return (
    <Layout location={location} title="Gedankenkreise stoppen" desc="Die Progressive Muskelentspannung wirkt besonders gut gegen Zähneknirschen und Verspannungen. Sag dem Knirschen den Kampf an." active="Gedankenkreise" green>
      <PageHeader title="Gedankenkreise stoppen" image={data.bildHeader} classNameImage="rounded-2xl mx-auto" wrapperClass="">
        <P className="mb-3">
          Wann hast du dir das letzte mal Gedanken gemacht? Wahrscheinlich vor wenigen Augenblicken. Denn das menschliche Gehirn verarbeitet pro Tag circa 11 Millionen Reize. Davon gelangen allerdings nur circa in unser Bewusstsein, damit
          wir nicht komplett durchdrehen. Unser Hirn funktioniert also wie eine Art Filter und selektiert zwischen wichtig und unwichtig. Das gilt auch für unsere Gedanken. Gedanken machen und Grübeln sind eine weitere wunderbare Funktion
          unseres Gehirns. Wir können lange über einem Sachverhalt nachdenken bis wir eine Lösung gefunden und über unerledigtes, oder bedrückendes nachsinnen und Grübeln. Denken und Grübeln unterscheiden sich grundlegend voneinander. Denn
          während Nachdenken einen Zweck erfüllt und bewusst passiert, grübeln wir oftmals Gedankenverloren vor uns hin.
        </P>
        <P className="mb-6">
          Da Gedankenkreise stoppen für viele Menschen eine Herausforderung geworden ist, wollen wir auf dieser Seite ein paar wirkungsvolle Lösungsansätze mit dir teilen, die du direkt für dich ausprobieren kannst. Auf dieser Seite findest
          du folgendes:
        </P>

        <FeatureList
          items={[
            { text: 'Einen Überblick über das Grübeln' },
            { text: 'Erfährst was du gegen Grübeln machen kannst' },
            { text: 'Lernst Methoden kennen mit denen du Grübeln stoppen kannst' },
            { text: 'Erfährst wo es eine Checkliste gegen Gedankenkreise gibt' },
            { text: 'Bekommst eine Kursempfehlung mit Krankenkassenzuschuss' }
          ]}
          className="space-y-5"
        />
      </PageHeader>

      <Container className="text-brand-gray">
        <div className="mx-auto max-w-2xl space-y-8">
          <div>
            <H2 className="mb-5 font-display">Eine Alltagsanekdote übers Grübeln</H2>
            <P className="mb-3">
              Doch bevor wir ins Thema einsteigen, möchte ich dir zunächst eine kleine Anekdote erzählen, die dir die Dramatik des Grübelns noch einmal vor Augen führt. Sie macht dir deutlich, dass Wissen über diese Methoden dir gar nichts
              nützt, wenn du das Gelernte nicht für dich anwendest. Daher schon jetzt meine Einladung. Probier all die Tipps gerne aus und entscheide was für dich zum Grübeln stoppen gut funktioniert.
            </P>
            <ImageVideo video="https://res.cloudinary.com/lautenschlager/video/upload/v1657255365/kunden/entspannungshelden/gruebeln-stoppen_dcjpek.mp4" image={data.videoCover} />
            <P className="mt-3">
              Was du aus meiner Geschichte lernen kannst? Grübeln verändert in den seltensten Fällen etwas an der Situation. Im Gegenteil, durch das pausenlose drüber nachsinnen, wo mein Schlüssel war, habe ich schlechte Laune bekommen,
              mich über mich selbst geärgert, Selbstzweifel und Ärger genährt.{' '}
            </P>
          </div>

          <div>
            <H2 className="mb-5 font-display">Gehirnbenutzer können Grübeln stoppen</H2>
            <P className="mb-3">
              Vera F. Birkenbiehl hat in ihren Vorlesungen häufig von Gehirnbesitzern und Gehirnbenutzern gesprochen. In der Rückschau komme ich mir definitiv wie erster vor. Denn auch wenn ich durch die jahrelange Praxis der Achtsamkeit
              und Meditation schon einen ersten Eindruck über die Fähigkeiten meines Gehirns habe, so nutze ich mein Potential in Gedankenkreisen häufig nicht. Insbesondere dann nicht, wenn mir nicht auffällt das ich in das Karussell
              einsteige. Daher benötigen alle Methoden die ich dir gleich vorstelle zunächst die Bewusstmachung darüber, dass du, oder auch ich ins Grübeln kommen. Hierbei kann dich das Autogene Training, dass als eine Form des
              Achtsamkeitstraining gilt unterstützen. Weitere Informationen und unser Kursangebot findest du hier:{' '}
              <Link to="/kurse/autogenes-training-onlinekurs/" className="underline">
                Autogenes Training Onlinekurs
              </Link>
              .
            </P>
          </div>

          <Cta headOne="Autogenes Training" headTwo="gegen Gedankenkreise" button={<Button to="/kurse/autogenes-training-onlinekurs/" text="Gedankenkreise stoppen" />} withoutContainer />

          <div>
            <H2 className="mb-5 font-display">Tipps und Tricks gegen das Grübeln</H2>
            <P className="mb-3">Unsere fünf wirkungsvollsten Methoden gegen Gedankenkreise sind die Folgenden:</P>
            <dl className="mb-3 space-y-3">
              <Disclosure title="Grübeln stoppen mit Achtsamkeit">
                <P>
                  Achtsamkeit bedeutet hierbei, dass du deine Gedankenkreise wertfrei bemerkst und wie vom anderen Ufer aus beobachtest. Aha, da dreht sich ein Gedanke im Kreis. Nimm dir einen Moment und beobachte genau, welche Bestandteile
                  dazu gehören, also welche Bedürfnisse, Sorgen, Ängste und Befürchtungen. Diese Analysephase beschäftigt dein Gehirn in der Regel so stark, dass du automatisch gedanklich stehen bleibst und das Grübeln stoppen kannst.
                </P>
              </Disclosure>
              <Disclosure title="Sinnesarbeit gegen Gedankenkreise">
                <P className="mb-3">
                  Ebenfalls der Achtsamkeit entsprungen ist die Arbeit mit den eigenen Sinnen. Setze visuelle, auditive und haptische Reize ein und unterstreiche es durch Gerüche oder Geschmack. Wenn du gerade in der Mittagspause bist und
                  von einem Gewitter im Kopf überrascht wirst, probiere Folgendes:
                </P>
                <P className="mb-3">
                  <strong>Visuell</strong>: betrachte 5 Dinge, die du ganz genau visuell erforschen kannst.
                </P>
                <P className="mb-3">
                  <strong>Auditiv</strong>: konzentriere dich auf 4 unterschiedliche Geräusche in deiner Umgebung.
                </P>
                <P className="mb-3">
                  <strong>Kinästhetisch</strong>: nimm 3 fühlbare Dinge wahr wie z.B. Luftfeuchtigkeit, Temperatur, ein Gefühl.
                </P>
                <P className="mb-3">
                  <strong>Olfaktorisch</strong>: erschnüffle 2 unterschiedliche Düfte in deiner unmittelbaren Umgebung.{' '}
                </P>
                <P className="mb-3">
                  <strong>Gustatorisch</strong>: gibt es einen markanten Geschmack, den du gerade wahrnehmen kannst?
                </P>
                <P>
                  Diese einfache Methode gibt deinem Gehirn eine trainierbare Abfolge und damit die Möglichkeit aus der Grübelei auszusteigen. Gleichzeitig schult sie deine Achtsamkeit und führt so zu einem höheren Bewusstsein und
                  Entspannung.
                </P>
              </Disclosure>
              <Disclosure title="Gedankenschubladen öffnen und schließen">
                <P>
                  Eine Visualisierung ist die der Gedankenschubladen. Stell dir dafür einen antiquierten Schrank mit unzähligen Schubladen. Auf einer dieser Schubladen steht mit einem Messingschild das jeweils passende Thema deines
                  Gedankenkreises. Die Schublade ist in guter Gesellschaft und befindet sich neben Selbstzweifeln, Ängsten und falschen Erwartungen. Nimm deinen Gedanken und schreib ihn mental auf und stecke ihn in die Schublade, die du
                  dann geschwind wieder zu machst. So kannst du dir sicher sein, dass der Gedanke nicht verloren geht, aber dich jetzt gerade nicht belasten muss.
                </P>
              </Disclosure>
              <Disclosure title="Namensfindung für Gedanken">
                <P>
                  Eine ganz wunderbare Strategie, die für die meisten Menschen gut funktioniert, ist die der Namensfindung für eine Gedankenspirale. Du kannst dein Grübeln stoppen, indem du wiederkehrenden Gedanken bestimmte Namen gibst.
                  Sowas wie Quatschi ein bis drei, oder Selbstzweifelicitas, Kritikerstin. Durch den spaßhaften Umgang mit den Gedanken fallen sie dir erstens besser und schneller auf und du kannst sie gleichzeitig besser loslassen. Daher
                  schreib dir eine Liste mit deinen nervigsten Gedanken und gib ihnen komische Namen!
                </P>
              </Disclosure>
              <Disclosure title="Entspannung gegen Grübeln">
                <P>
                  Entspannung durch Autogenes Training, Meditation, oder die Progressive Muskelentspannung suggerieren deinem Gehirn, dass es an der Zeit ist zu entspannen. Grübeln hingegen versetzt dich eher in Alarmbereitschaft und
                  aktiviert dein Nervensystem. Systematische Entspannung wirkt daher wie eine paradoxe Intervention und hilft dir, dass Gedankenkreisen zu stoppen. Insbesondere das Autogene Training möchte ich dir ans Herz legen, da die
                  sieben Grundformeln eine extrem wirkungsvolle Möglichkeit sind, deine Gedanken in gewünschte Bahnen zu lenken und sie dort zu fokussieren wo du sie haben möchtest. Unser Onlinekursangebot wird dabei sogar von deiner
                  gesetzlichen Krankenkasse bezuschusst.
                </P>
              </Disclosure>
            </dl>
          </div>

          <Cta headOne="Autogenes Training" headTwo="gegen Gedankenkreise" button={<Button to="/kurse/autogenes-training-onlinekurs/" text="Gedankenkreise stoppen" />} withoutContainer />

          <div>
            <H2 className="mb-5 font-display">Noch mehr Impulse gefällig?</H2>
            <P>
              Weitere Informationen zum Thema Grübeln findest du auf unserem Blog im Artikel:{' '}
              <Link to="/blog/wie-du-gruebeln-stoppen-kannst/" className="underline">
                Wie du grübeln stoppen kannst
              </Link>
              . Dort findest du neben weiteren Methoden gegen Gedankenkreise auch eine kleine Checkliste zum kostenfreien Download, die du dir mehrfach ausdrucken und bei Grübelattacken einsetzen kannst.
            </P>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default Page;

export const query = graphql`
  {
    bildHeader: file(relativePath: { eq: "pages/herausforderungen/gedankenkreise/gedankenkreise_header.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    videoCover: file(relativePath: { eq: "video/gruebeln-stoppen.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
